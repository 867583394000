<template>
  <div class="relative">
    <div
      class="
        px-3
        text-gray-800
        transition-colors
        duration-150
        bg-white
        border-2 border-solid
        rounded-cu
        w-full
        hover:border-gray-600
        focus:outline-none
        focus:border-dokBlue-ultra
        h-12
        flex
        items-center
        justify-between
        cursor-pointer
      "
      :class="customClass"
      @click="displyMenu = !displyMenu"
    >
      <span
        class="px-2 text-gray-700 text-lg font-EffraR"
        v-if="!infoCategory._id"
        >Sélectionner un motif</span
      >

      <span
        class="px-2 text-lg text-shadow font-EffraR"
        :style="`color: ${infoCategory.color};`"
        v-if="infoCategory._id"
      >
        {{ infoCategory.title }}
      </span>

      <img src="/svg/chevron-down.svg" />
    </div>
    <div
      class="
        absolute
        left-0
        w-full
        p-2
        my-5
        space-y-2
        bg-white
        border border-gray-100
        rounded-lg
        z-5
      "
      style="
        box-shadow: 0 8px 52px 0 rgba(0, 0, 0, 0.08);
        border: solid 2px #aaaaaa;
      "
      v-if="displyMenu"
    >
      <div
        class="overflow-y-scroll scrollModal flex flex-col gap-y-5"
        style="max-height: 318px"
      >
        <div class="grid grid-cols-2 gap-y-3 gap-x-2 pr-2">
          <div
            v-for="(cat, indexcat) in data"
            :key="indexcat"
            class="cursor-pointer w-full border border-solid px-5 py-2 rounded"
            :style="`border-color: ${cat.color}; color: ${cat.color};`"
            @click="categorySelect = cat._id"
          >
            <div class="flex items-center">
              <input
                type="radio"
                class="
                  form-radio
                  border-2 border-solid border-gray-600
                  focus:outline-none
                  text-lg
                  mr-2
                "
                :value="cat._id"
                v-model="categorySelect"
              />
              <span class="truncate font-EffraR text-shadow">{{
                cat.title
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="font-EffraR text-lg flex items-center gap-x-3 cursor-pointer"
        @click="createNewMotif"
      >
        <i class="fas fa-plus-circle"></i> Ajouter un nouveau motif
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
import { find } from "lodash";

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    category: {
      type: String,
      required: false
    },
    customClass: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      displyMenu: false,
      infoCategory: {}
    };
  },
  computed: {
    categorySelect: {
      get() {
        return this.$store.state.agenda.selectedSlot;
      },
      set(value) {
        let motifInfo = find(this.data, data => data._id === value);
        if (motifInfo) {
          this.infoCategory = motifInfo;

          this.$store.commit("agenda/SET_SELECTED_SLOT", value);
          this.$emit("update:category", value);
          this.displyMenu = false;
        }
      }
    }
  },
  methods: {
    async createNewMotif() {
      const navigationResult = await this.$router.push({
        name: "settingsAgenda"
      });
      if (navigationResult) {
        EventBus.$emit("Click_new_motif");
      }
    }
  }
};
</script>

<style lang="scss">
.scrollModal {
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    @apply bg-gray-600;
    @apply rounded-lg;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    @apply bg-gray-700;
  }
}
</style>
